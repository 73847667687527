import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote-modal"
export default class extends Controller {
  initialize() {
    $(".modal-body").bootstrapMaterialDesign();
    $('#remotemodal').modal('show');
    this.go_back_on_modal_hide()
  }

  go_back_on_modal_hide(){
    $("#remotemodal").on('hide.bs.modal', function(){
      history.back();
    });
  }
}
