import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="citation-report"
export default class extends Controller {
  static targets = [ "recordIdSelect", "recordTypeSelect", "companyIdSelect" ]
  static values = {
    filterDropdowns: String,
  }

  companyChange() {
    this.populateRecordIdSelect();
  }

  recordTypeChange() {
    this.populateRecordIdSelect();
  }

  populateRecordIdSelect() {
    const companyId = this.hasCompanyIdSelectTarget ? this.companyIdSelectTarget.value : null;
    const recordType = this.recordTypeSelectTarget.value;
    const recordIdSelect = this.recordIdSelectTarget;
    recordIdSelect.innerHTML = "";

    const filterDropdowns = JSON.parse(this.filterDropdownsValue);
    const recordIdOptions = filterDropdowns[recordType];

    if (!recordIdOptions) return;

    recordIdOptions.forEach(([text, value, data]) => {
      if (companyId && data && parseInt(data.company_id) !== parseInt(companyId)) return;

      const optionElement = document.createElement("option");
      optionElement.value = value;
      optionElement.text = text;
      recordIdSelect.appendChild(optionElement);
    });
  }
}
