import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import swal from "sweetalert2";

// Connects to data-controller="citation"
export default class extends Controller {
  static targets = [ "select", "state", "make", "model", "color", "vehicleID", "vehicleHistory", "customOffenses",
                     "vehicleHistoryBody", "offensesList", "attachedToSelect", "lotSelect",
                     "form", "licencePlate", "invalidTTError" ]
  static values = {
    vehicleUrl: String,
    newCitation: Boolean,
    errors: String,
    lotId: Number
  }

  lotSelectTargetConnected () {
    if (this.lotSelectTarget.dataset.lotSelectInitialized) return;

    this.setClosestLot();

    this.lotSelectTarget.dataset.lotSelectInitialized = 'true';
  }

  async setClosestLot() {
    if (this.newCitationValue){
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async function(position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            let closest_lot = await this.fetchClosestLot(lat, lng);
            if (closest_lot) {
              this.lotSelectTarget.value = closest_lot;
              let event = new Event('change', {'bubbles': true, 'cancelable': true});
              this.lotSelectTarget.dispatchEvent(event);
              swal.fire("Lot Verification", "The lot has been auto-filled based on your location. Please verify before creating the citation.", "info");
            }
          }.bind(this)
        );
      }
    }
  }

  async fetchClosestLot(latitude, longitude) {
    const url = `/lots/closest?latitude=${latitude}&longitude=${longitude}`;
    const response = await get(url, { responseKind: "json" });
    if(response.ok) {
      const data = await response.json;
      if (data.closest_lot_id) {
        return data.closest_lot_id;
      }
    }
  }

  lotChange() {
    this.designated_signage(event.target.selectedOptions[0].dataset.designatedSignage)

    let lot_id = event.target.selectedOptions[0].value
    this.fetchOffenses(lot_id)
    let attached_to = event.target.selectedOptions[0].dataset.tiretagAttachedTo
    this.attachedToSelectTarget.value = (typeof attached_to !== "undefined") ? attached_to : ""
    this.set_state_based_on_lot()
    this.populateVehicleData()
  }

  designated_signage(path){
    const postage_image = document.getElementById("posted_signage_image")
    if (postage_image) {
      const icon = postage_image.parentElement.querySelector(".image-upload-badge")
      const preview = postage_image.parentElement.querySelector(".image-preview")
      const close = postage_image.parentElement.querySelector(".delete-image-button")
      if (path) {
        postage_image.src = path
        preview.classList.remove('d-none');
        close.classList.remove("d-none")
        icon.classList.add("d-none")
      }
      else {
        postage_image.src = ""
        preview.classList.add('d-none');
        close.classList.add("d-none")
        icon.classList.remove("d-none")
      }
    }
  }

  set_state_based_on_lot() {
    let lot_id = event.target.value
    let lot_state = event.target.selectedOptions[0].dataset.lotState.toLowerCase()
    let stateOptions = this.stateTarget.options
  
    for (let i = 0; i < stateOptions.length; i++) {
      if (stateOptions[i].text.toLowerCase() === lot_state) {
        this.stateTarget.selectedIndex = i;
        this.stateTarget.dispatchEvent(new Event('change'));
        break;
      }
    }
  }

  async fetchOffenses(lot_id) {
    if (lot_id) {
      get(`/lots/${lot_id}/offenses`, { responseKind: "turbo-stream" })
      this.customOffensesTarget.classList.remove("d-none")
    }
    else {
      this.offensesListTarget.innerHTML = `<p class="alert alert-info" role="alert">Please select a lot first </p>`
      this.customOffensesTarget.classList.add("d-none")
    }
  }

  async populateVehicleData() {
    let licence_plate = this.licencePlateTarget.value
    if (licence_plate.length <= 0){
      this.vehicleIDTarget.innerHTML = ""
      this.vehicleHistoryTarget.classList.add("d-none")
      this.whitelistedVehicle = false;
      return
    }

    const lotId = this.hasLotSelectTarget ? this.lotSelectTarget.value : this.lotIdValue;
    const response = await get(this.vehicleUrlValue, { query: { licence_plate: licence_plate, lot_id: lotId }, responseKind: "json" })
    if (response && response.ok) {
      const vehicle = await response.json
      if (vehicle.error) {
        this.vehicleIDTarget.innerHTML = ""
        this.vehicleHistoryTarget.classList.add("d-none")
      }
      else {
        this.stateTarget.value = vehicle.state
        this.stateTarget.dispatchEvent(new Event('change'));
        this.makeTarget.value = vehicle.make
        this.modelTarget.value = vehicle.model
        this.colorTarget.value = vehicle.color

        this.vehicleHistoryBodyTarget.innerHTML = this.vehicleHistoryHtml(vehicle)
        this.vehicleHistoryTarget.classList.remove("d-none")


        let vehicle_id_html = `
        <input type="hidden" value="${vehicle.id}" name="citation[vehicle_attributes][id]" id="citation_vehicle_attributes_id">`
        if (this.newCitationValue){
          vehicle_id_html += `<input type="hidden" value="${vehicle.id}" name="citation[vehicle_id]" id="citation_vehicle_id">`
        }
        this.vehicleIDTarget.innerHTML = vehicle_id_html

        if (vehicle.whitelisted && this.newCitationValue) {
          this.whitelistedVehicle = true;
          swal.fire("Whitelisted Vehicle", "This vehicle is whitelisted for the selected lot.", "info");
        }
        else{
          this.whitelistedVehicle = false;
        }
      }
    }
  }

  vehicleHistoryHtml(vehicle) {
    return `
      <tr>
        <th scope="row">Past citations</th>
        <td class="font-weight-bold">${vehicle.citations.all}</td>
      </tr>
      <tr>
        <th scope="row">Unpaid citations</th>
        <td class="font-weight-bold">${vehicle.citations.unpaid}</td>
      </tr>
      <tr>
        <th scope="row">Citation Number(s)</th>
        <td class="font-weight-bold">${vehicle.vehicle_citations.citation_numbers}</td>
      </tr>
      <tr>
        <th scope="row">Citation TireTag(s)</th>
        <td class="font-weight-bold">${vehicle.vehicle_citations.tire_tags}</td>
      </tr>
    `
  }

  previewImage(event){
    var input = event.target

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      const icon = input.parentElement.querySelector(".image-upload-badge")
      const preview = input.parentElement.querySelector(".image-preview")
      const close = input.parentElement.querySelector(".delete-image-button")

      reader.onload = function (e) {
        preview.src = reader.result
        preview.classList.remove('d-none');
        if (close) close.classList.remove("d-none")
        if (icon) icon.classList.add("d-none")
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  removeImage(event){
    event.preventDefault();
    var input = event.target
    const icon = input.parentElement.querySelector(".image-upload-badge")
    const preview = input.parentElement.querySelector(".image-preview")
    const image_file = input.parentElement.querySelector(".image-upload-input")

    icon.classList.remove("d-none")
    event.target.classList.add("d-none")
    preview.classList.add("d-none")
    preview.removeAttribute("src")
    image_file.value = ""
  }

  async saveCitation(event) {
    event.preventDefault();

    var formTarget = this.formTarget;

    if (this.whitelistedVehicle) {
      this.whitelisted_vehicle_confirmation(formTarget)
    }
    else {
      formTarget.requestSubmit();
    }
  }

  whitelisted_vehicle_confirmation(formTarget){
    swal.fire({
      title: "Whitelisted Vehicle",
      text: "This vehicle is whitelisted for the selected lot. Would you like to still issue a citation?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      didOpen: () => {
        document.querySelector('.swal2-deny').focus();
      }
    }).then((result) => {
      if (result.isConfirmed) {
        formTarget.requestSubmit();
      }
    });
  }

  async invalidTTErrorTargetConnected() {
    const { value: color } = await swal.fire({
      title: "Invalid Tire Tag",
      html: this.errorsValue,
      icon: "error",
      input: "radio",
      inputOptions: { "yes": "Yes", "no": "No" },
      showCancelButton: true,
      confirmButtonText: "Submit",
      inputValidator: (value) => {
        if (!value) {
          return "You need to select an option!";
        }
      }
    });
    if (color === "yes") {
      const citationForm = document.getElementsByClassName("citation-form")[0]
      this.addHiddenField(citationForm, 'verifyTT', true);
      citationForm.requestSubmit();
    }
  }

  addHiddenField(form, name, value) {
    let input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    input.setAttribute("value", value)
    form.appendChild(input)
  }

  handleOffenseCheckboxChange(event) {
    if (this.lotSelectTarget.selectedOptions[0].dataset.allowDuplicateOffenses == 'false') return;

    const checkbox = event.currentTarget;
    const offenseItem = checkbox.closest('.offense-item');
    const offenseControls = offenseItem.querySelector('.offense-controls');
    const countSpan = offenseControls.querySelector('.offense-count');

    if (checkbox.checked) {
      offenseControls.classList.remove('d-none');
      countSpan.textContent = '1';
    } else {
      offenseControls.classList.add('d-none');
      offenseItem.querySelector('.offenses-hidden-fields').innerHTML = '';
      countSpan.textContent = '0';
    }
  }

  addDuplicateOffense(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const offenseItem = button.closest('.offense-item');
    const checkbox = offenseItem.querySelector('.offense-checkbox');
    const hiddenFieldsContainer = offenseItem.querySelector('.offenses-hidden-fields');
    const countSpan = offenseItem.querySelector('.offense-count');
    let count = parseInt(countSpan.textContent, 10);

    if (button.classList.contains('increase-offense')) {
      count++;
      if (count > 1) {
        this.addHiddenField(hiddenFieldsContainer, 'citation[offense_ids][]', checkbox.value);
      }
    } else if (count > 1) {
      count--;
      const hiddenFields = hiddenFieldsContainer.querySelectorAll('input[type="hidden"]');
      if (hiddenFields.length > 0) {
        hiddenFieldsContainer.removeChild(hiddenFields[hiddenFields.length - 1]);
      }
    }

    countSpan.textContent = count;
  }
}
