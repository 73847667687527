import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charts"
export default class extends Controller {
  static targets = [ "revenueSummary", "citationActivity", "citationSummary", "tiretagSummary" ]
  static values = {
    stats: Object,
  }

  revenueSummaryTargetConnected(){
    var data = this.statsValue.current_month_revenue
    var colors = {"backgroundColor": "rgb(255, 99, 132)", "borderColor": "rgb(255, 99, 132)"};
    this.initLineChart(this.revenueSummaryTarget, data, colors, "Revenue ($)", 100)
  }
  
  citationActivityTargetConnected(){
    var data = this.statsValue.current_month_citations
    var colors = {"backgroundColor": "", "borderColor": "rgb(0, 0, 255)"};
    this.initLineChart(this.citationActivityTarget, data, colors, "Citations", 1)
  }

  citationSummaryTargetConnected(){
    var data = this.statsValue.citations
    var colors = ["green", "orange", "red", "purple", "grey"]
    this.initPieChart(this.citationSummaryTarget, data, colors, "Citations Summary")
  }

  tiretagSummaryTargetConnected(){
    var data = this.statsValue.tiretags
    var colors = ["blue", "green", "yellow"]
    this.initPieChart(this.tiretagSummaryTarget, data, colors, "Tire Tags Summary")
  }

  initLineChart(target, data, colors, ylabel, stepSize) {
    let chart = new Chart(target.getContext("2d"), {
      // The type of chart we want to create
      type: 'line',

      // The data for our dataset
      data: {
          labels: Object.keys(data),
          datasets: [{
              backgroundColor: colors["backgroundColor"],
              borderColor: colors["borderColor"],
              data: Object.values(data),
          }]
      },

      // Configuration options go here
      options: {
          title: {
              display: false
          },
          legend: {
              display: false
          },
          scales: {
              yAxes: [{
                  ticks: { min: 0, stepSize: stepSize }
                  , scaleLabel: {
                      display: true,
                      labelString: ylabel,
                  },
              }],
              xAxes: [{
                  scaleLabel: {
                      display: true,
                      labelString: "Days",
                  },
              }]
          }
      }
    });
  }

  initPieChart(target, data, colors, label) {
    const labels = Object.keys(data).map(element => 
      {
        return element.split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ');
      });
    let chart = new Chart(target.getContext("2d"), {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
            backgroundColor: colors,
            data: Object.values(data),
        }]
      },
      options: {
          responsive: true,
          title: {
              display: true,
              text: label
          },
          legend: {
              display: true,
              position: "right"
          }
      }
    });
  }
}
