import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";
import { get } from "@rails/request.js";

// Connects to data-controller="assign-employees"
export default class extends Controller {
  static targets = [ "droppable" ]
  static values = {
    url: String,
  }

  initialize(){
    this.count = 1
  }

  async droppableTargetConnected(){
    
    if (this.count == 1){
      this.count += 1;
      let url = this.urlValue

      dragula({
        containers: [...document.querySelectorAll(".droppable"), document.querySelector("#company_employees")],
        copy: true,
        accepts: function (el, target, source, sibling) {
          return source.id === "company_employees" && target.id !== "company_employees" 
        }
        }).on("drop", async function (el, target) {
        if (el && target){
          var user_id = el.dataset.userId
          var zone_id = target.dataset.zoneId
          el.id = `newly_assigned_zone_${zone_id}_user_${user_id}`
          const response = await post(url, { body: { zone_id: zone_id, user_id: user_id }, responseKind: "turbo-stream" })
        }
      });
    }
  }
}
