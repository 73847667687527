import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timezone"
export default class extends Controller {
  static targets = [ "timezoneField" ]

  connect() {
  }

  timezoneFieldTargetConnected() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.timezoneFieldTarget.value = timezone
  }
}
