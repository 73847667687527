// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

window.initAutocomplete = function () {
  const event = new Event("google-maps-callback", {
    bubbles: true,
    cancelable: true,
  });
  window.dispatchEvent(event);
};

$(document).ready(apply_theme);
$(document).on("turbo:render", apply_theme);

function apply_theme() {
  // Initialize bootstrap material
  $("body").bootstrapMaterialDesign();

  // Also initialize bootstrap material in modal windows
  $(document).on("show.bs.modal", function () {
    $(".modal-body").bootstrapMaterialDesign();
  });

  // Initialize all tooltips
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  // Initialize tooltips on buttons that also open modal windows
  $('[data-toggle="modal"][title]').tooltip();

  // Show/hide sidebar on window resize
  $(window).resize(showSidebar).trigger("resize");

  // Hide flash message after 3 seconds
  if ($(".alert-dismissible", "body").length > 0) {
    setTimeout(function () {
      $(".alert-dismissible").slideUp("slow", function () {
        $(this).remove();
      });
    }, 3000);
  }
}
function showSidebar() {
  if ($(window).width() > 992) {
    $("div.bmd-layout-container").addClass("bmd-drawer-in");
    $("div.bmd-layout-container").removeClass("bmd-drawer-overlay");
  } else {
    $("div.bmd-layout-container").removeClass("bmd-drawer-in");
    $("div.bmd-layout-container").addClass("bmd-drawer-overlay");
  }
}
