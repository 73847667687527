import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-preview"
export default class extends Controller {
  static targets = [ "defaultPreview", "imagePreviewContainer" ]

  preview(){
    var fileField = event.currentTarget

    if (fileField.files && fileField.files[0]) {
      var reader = new FileReader();
      var ip = this.imagePreviewContainerTarget.querySelector("#ImagePreview")

      reader.onload = function (e) {
        ip.src = reader.result
      };
      reader.readAsDataURL(fileField.files[0]);

      this.defaultPreviewTarget.classList.remove("d-flex")
      this.defaultPreviewTarget.classList.add("d-none")
      this.imagePreviewContainerTarget.classList.remove("d-none")
      this.imagePreviewContainerTarget.classList.add("d-flex")

    }
  }
}
