import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="search"
export default class extends Controller {
  static values = { url: String, field: String }

  search(event) {
    const query = event.target.value.trim();
    this.sendTurboStreamRequest(query);
  }

  async sendTurboStreamRequest(query) {
    var urlParams = "q%5B" + encodeURIComponent(this.fieldValue) + "%5D=" + encodeURIComponent(query);
    const url = `${this.urlValue}&${urlParams}`;
    await get(url, {
      responseKind: 'turbo-stream'
    });
  }

  released_tiretags(event) {
    const isChecked = event.target.checked;
    this.sendTurboStreamRequest(isChecked);
    let urlParams = "q%5B" + encodeURIComponent(this.fieldValue) + "%5D=" + encodeURIComponent(isChecked);
    window.history.pushState({}, '', window.location.pathname + '?' + urlParams);
  }
}
