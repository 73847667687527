import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import swal from "sweetalert2";

// Connects to data-controller="tire-tag"
export default class extends Controller {
  static targets = [ "select", "selectDiv", "tiretagsNumberDiv", "tiretagFieldsDiv",
                     "validReleaseCodeDiv", "validReleaseCode", "tiretagFields",
                     "statusChangeMsg", "damagedTireTagImageDiv", "bulkUpdateIds", "voidNoteDiv", "voidNote" ]
  static values = {
    url: String,
    oldStatus: String
  }

  companyChange(event) {
    let company_id = event.target.selectedOptions[0].value
    if (company_id) {
      this.selectDivTarget.classList.remove("d-none")
      this.fetchZones(company_id)
    }
    else{
      this.selectDivTarget.classList.add("d-none")
    }
  }

  async fetchZones(company_id) {
    await get(this.urlValue, { query: { company_id: company_id, target: this.selectTarget.id }, responseKind: "turbo-stream" })
  }

  zoneChange(event) {
    let zone_id = event.target.selectedOptions[0].value
    if (zone_id) {
      this.tiretagsNumberDivTarget.classList.remove("d-none")
    }
    else{
      this.tiretagsNumberDivTarget.classList.add("d-none")
    }
  }

  ttsNumberChange(event) {
    let tiretags_count = event.target.selectedOptions[0].value
    if (tiretags_count) {
      this.tiretagFieldsDivTarget.classList.remove("d-none")
    }
    else {
      this.tiretagFieldsDivTarget.classList.add("d-none")
    }

    let tiretag_fields_html = ""
    for (let i = 1; i <= tiretags_count; i++) {
      tiretag_fields_html += this.tiretag_fields(i)
    }
    this.tiretagFieldsTarget.innerHTML = tiretag_fields_html
  }

  tiretag_fields(i) {
    return `<div class="col-sm-6 p-0 m-0">
      <div class="row">
        <div class="col-auto">
          <h6>${i} - </h6>
        </div>
        <div class="col-5">
          <div class="form-group bmd-form-group">
            <label for="tire_tag_fields_${i}_serial_number" class="bmd-label-static">Serial Number</label>
            <input type="text" name="tire_tag[fields[${i}][serial_number]]" id="tire_tag_fields_${i}_serial_number" value="" class="form-control" required="required">
          </div>
        </div>
    
        <div class="col-5">
          <div class="form-group bmd-form-group">
            <label for="tire_tag_fields_${i}_activation_code" class="bmd-label-static">Activation code</label>
            <input type="number" name="tire_tag[fields[${i}][activation_code]]" id="tire_tag_fields_${i}_activation_code" value="" step="1" class="form-control" required="required" min="0" max="10000000">
          </div>
        </div>
      </div>
    </div>`
  }

  statusChange(event) {
    let new_status = event.target.selectedOptions[0].value
    let msg = this.status_change_msg(this.oldStatusValue,new_status )
    if (msg) this.statusChangeMsgTarget.innerHTML = msg
    if (new_status == "ready"){
      if (this.oldStatusValue == "released") {
        this.validReleaseCodeDivTarget.classList.remove("d-none");
        this.validReleaseCodeTarget.setAttribute("required", true);
      }
      if (this.oldStatusValue == "deployed" || this.oldStatusValue == "unreleased") {
        this.voidNoteDivTarget.classList.remove("d-none");
        this.voidNoteTarget.setAttribute("required", true);
      }
    }else{
      if (this.oldStatusValue == "released") {
        this.validReleaseCodeDivTarget.classList.add("d-none");
        this.validReleaseCodeTarget.removeAttribute("required");
      }
      if (this.oldStatusValue == "deployed" || this.oldStatusValue == "unreleased") {
        this.voidNoteDivTarget.classList.add("d-none");
        this.voidNoteTarget.removeAttribute("required");
      }
    }
    new_status == "damaged" ? this.damagedTireTagImageDivTarget.classList.remove("d-none") : this.damagedTireTagImageDivTarget.classList.add("d-none")
  }

  status_change_msg(old_status, new_status) {
    if ((old_status == "deployed" || old_status == "unreleased") && new_status == "ready")
      return "The citation associated with this tire tag will be unpaid. Continuing will void the citation. This action cannot be undone."
    else if (old_status == "released") {
      if (new_status == "ready")
        return "This tire tag has not been reported as found yet and will be marked as such on submission. This action will release the holding fee to the offender."
      else if (new_status == "unreturned")
        return "This tire tag has not been reported as lost yet and will be marked as such on submission. This action will capture the holding fee from the offender."
      else if (new_status == "damaged")
        return "This action will capture the holding fee from the offender."
    }
    return ""
  }

  bulkEdit() {
    if (this.get_tire_tag_ids().length <= 0) {
      event.preventDefault()
      swal.fire("Notice", "Please select one or more tire tags to bulk update.", "warning");
    }
  }

  bulkUpdateIdsTargetConnected() {
    let tire_tag_ids = this.get_tire_tag_ids()
    if (tire_tag_ids.length > 0) this.bulkUpdateIdsTarget.innerHTML = this.tire_tags_ids_html(tire_tag_ids)
  }

  get_tire_tag_ids() {
    return Array.from(document.querySelectorAll("input[class='tire_tag_check_boxes']:checked")).map((elem) => elem.value)
  }

  tire_tags_ids_html(tire_tag_ids) {
    let html = ""
    tire_tag_ids.forEach((tire_tag_id) => {
      html += `<input type="hidden" name="tire_tag_ids[]" value="${tire_tag_id}">`
    })
    return html
  }

  toggleAllCheckboxes(event) {
    const isChecked = event.target.checked
    document.querySelectorAll(".tire_tag_check_boxes").forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }
}
