import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="employee"
export default class extends Controller {
  static targets = [ "zoneSelect", "regionSelect", "regionSelectDiv", "zoneSelectDiv"]



  regionSelectTargetConnected () {
    if (this.regionSelectTarget.dataset.slimSelectInitialized) return;

    let placeholder = this.regionSelectTarget.dataset.placeholder || 'Select Region';
    this.regionSlimSelect = new SlimSelect({ select: this.regionSelectTarget, placeholder: placeholder });

    this.regionSelectTarget.dataset.slimSelectInitialized = 'true';
  }

  zoneSelectTargetConnected () {
    if (this.zoneSelectTarget.dataset.slimSelectInitialized) return;

    let placeholder = this.zoneSelectTarget.dataset.placeholder || 'Select Zone';
    this.zoneSlimSelect = new SlimSelect({ select: this.zoneSelectTarget, placeholder: placeholder });

    this.zoneSelectTarget.dataset.slimSelectInitialized = 'true';
  }

  roleChange(event) {
    if (!this.hasRegionSelectDivTarget && !this.hasZoneSelectDivTarget) return;

    var role = event.target.value;
    if (role == "region_manager") {
      this.regionSelectDivTarget.classList.remove("d-none");
      this.zoneSelectDivTarget.classList.add("d-none");

      this.regionSlimSelect.enable();
      this.zoneSlimSelect.disable();
    }
    else if (role == "zone_manager" || role == "applicator") {
      this.regionSelectDivTarget.classList.add("d-none");
      this.zoneSelectDivTarget.classList.remove("d-none");

      this.regionSlimSelect.disable();
      this.zoneSlimSelect.enable();
    }
  }
}
