import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company"
export default class extends Controller {
  static targets = [ "referralPartnerFields", "referralPartnerSelect", "referralPartnerFee" ]

  showreferralPartnerFields() {
    this.referralPartnerFieldsTarget.classList.remove("d-none")
    this.referralPartnerSelectTarget.disabled = false;
    this.referralPartnerFeeTarget.disabled = false;

  }
  hidereferralPartnerFields() {
    this.referralPartnerFieldsTarget.classList.add("d-none")
    this.referralPartnerSelectTarget.disabled = true;
    this.referralPartnerFeeTarget.disabled = true;
  }
}
