import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lot"
export default class extends Controller {
  static targets = [ "feeAmount", "feeDropdown" ]

  lotFeeChange() {
    var feeAmount = this.feeDropdownTarget.selectedOptions[0].dataset.feeAmount
    this.feeAmountTarget.value = (typeof feeAmount !== "undefined") ? feeAmount : ""
  }

  feeAmountTargetConnected(){
    this.lotFeeChange()
  }
}
