import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"
export default class extends Controller {
  static targets = [ "multiselect", "singleselect" ]

  multiselectTargetConnected () {
    if (this.multiselectTarget.dataset.slimSelectInitialized) return;

    let placeholder = this.multiselectTarget.dataset.placeholder || 'Select Option';
    new SlimSelect({ select: this.multiselectTarget, placeholder: placeholder });

    this.multiselectTarget.dataset.slimSelectInitialized = 'true';
  }

  singleselectTargetConnected () {
    if (this.singleselectTarget.dataset.slimSelectInitialized) return;

    new SlimSelect({ select: this.singleselectTarget, showSearch: true });

    this.singleselectTarget.dataset.slimSelectInitialized = 'true';
  }
}
