import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

// Connects to data-controller="geocoder"
export default class extends Controller {
  static targets = ["address", "city", "streetNumber", "route", "state", "postalCode", "country", "longitude", "latitude"];

  fetchCurrentLocation() {
    event.preventDefault();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const geocoder = new google.maps.Geocoder;
          const latlng = {lat, lng};
          
          geocoder.geocode({'location': latlng}, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                this.addressTarget.value = results[0].formatted_address;
                const addressComponents = results[0].address_components;
                
                addressComponents.forEach(component => {
                  const types = component.types;
                  
                  if (types.includes("locality")) {
                    this.cityTarget.value = component.long_name;
                  }
                  if (types.includes("street_number")) {
                    this.streetNumberTarget.value = component.long_name;
                  }
                  if (types.includes("route")) {
                    this.routeTarget.value = component.long_name;
                  }
                  if (types.includes("administrative_area_level_1")) {
                    this.stateTarget.value = component.long_name;
                  }
                  if (types.includes("postal_code")) {
                    this.postalCodeTarget.value = component.long_name;
                  }
                  if (types.includes("country")) {
                    this.countryTarget.value = component.long_name;
                  }
                });
                
                this.longitudeTarget.value = lng;
                this.latitudeTarget.value = lat;
              }
            }
          });
      }.bind(this),
      function(error) {
        swal.fire("Location Permission Denied", "Unable to auto-populate current location.", "warning");
      }
      );
    }
  }
}
