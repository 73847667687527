import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert2";

// Connects to data-controller="sweet-alert"
export default class extends Controller {
  static targets = ["swalError", "swalSuccess"];
  static values = {
    errors: String,
    success: String,
    redirectBack: Boolean,
    title: String,
  };

  swalErrorTargetConnected() {
    swal.fire("", this.errorsValue, "error");
  }

  swalSuccessTargetConnected() {
    if (this.redirectBackValue){
      swal.fire({
        title: this.titleValue || "Success",
        html: this.successValue,
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result) window.history.back()
      });

    }else{
      swal.fire("Success", this.successValue, "success");
    }
  }
}
